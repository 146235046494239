import React, { useEffect, useRef, useState } from 'react';
import Madam from '../../Assets/Madam.svg';
import Teacher from '../../Assets/Teacher.svg';
import GraphLoader from '../../Assets/GraphLoader.gif';
import { useParams } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import Class from '../../Assets/Class.svg';
import School from '../../Assets/School.svg';
import ActualTime from '../../Assets/ActualTime.svg';
import Achievement from '../../Assets/Achievement.svg';
import Percentage from '../../Assets/Percentage.svg';
import TeacherEffort from './TeacherEffort';

const TeacherDashFull= () => {

  const { teacherId, className, schoolName} = useParams();

  const [loader, setLoader] = useState(false);

  const [effortData, setEffortData] = useState('');
  const [resultData, setResultData] = useState('');

  const [classEffortCharts, setClassEffortCharts] = useState({
    classSubTopicData: [],
    stateSubTopic: [],
    classCognitiveRigor: [],
    stateCognitiveRigor: [],
    classDifficulty: [],
    stateDifficulty: []
  });

  const [classResultCharts, setClassResultCharts] = useState({
    classSubTopicData: [],
    stateSubTopic: [],
    classCognitiveRigor: [],
    stateCognitiveRigor: [],
    classDifficulty: [],
    stateDifficulty: [],
    overAllPerformance: []
  });


  // const [classSubTopicData, setClassSubTopicData] = useState('');
  // const [stateSubTopic, setStateSubTopic] = useState('');
  // const [classCognitiveRigor, setClassCognitiveRigor] = useState('');
  // const [stateCognitiveRigor, setStateCognitiveRigor] = useState('');
  // const [classDifficulty, setClassDifficulty] = useState('');
  // const [stateDifficulty, setStateDifficulty] = useState('');
  // const [overAllPerformance, setOverAllPerformance] = useState('');


  useEffect(()=>{
    getContentWatchTimeReport("Class Effort");
    getContentWatchTimeReport("Class Result");
  },[])

  
  const getContentWatchTimeReport = async(type)=>{
      
    try{
      setLoader(true);
      const res = await fetch(process.env.REACT_APP_BASE_URL + '/cwtr/ContentWatchTimeReportList',{
        method:'POST',
        headers:{'Content-Type':'application/json'},
        body:JSON.stringify({ teacherId: teacherId, className: className,
                              schoolName: schoolName,
                              type: type // ["Class Effort", "Class Result"]})
        })
      });
    
    const response = await res.json();
      // type === "Class Effort" ? console.log("Effort", response) : console.log("result", response);
      type === "Class Effort" ? setEffortData(response) : setResultData(response);
      processedData(response, type);
      setLoader(false);
    }catch(Err){
      console.log("Err while getting ContentWatchTimeReport =>", Err);
      setLoader(false);
    }
  }

  /* ----- Processing data for charts -------- */
    const processedData = (response, key)=>{

       //---- For 1st chart - Class Subject Topic ----------------
       let dataArray = [];
          dataArray.push({
            type: 'line',
            label: key === "Class Effort" ? "Watch Percentage" : "Watch Percentage",
            fill: false,
            data: response?.classSubjectTopic?.z,
            borderWidth: 2,
            borderColor: 'rgba(0, 9, 128, 0.941)', 
            backgroundColor: 'rgba(0, 48, 170, 0.2)'
            // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

        dataArray.push({
            type: 'bar',
            label: key === "Class Effort" ? "Average Open" : "Average Open",
            fill: false,
            data: response?.classSubjectTopic?.y,
            borderWidth: 2,
            borderColor: 'rgb(168, 90, 0)', 
            backgroundColor: 'rgba(255, 165, 62, 0.473)'
            // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

          //---- For 2st chart - State Subject Topic ----------------
          let dataArray2 = [];
          dataArray2.push({
            type: 'line',
            label: key === "Class Effort" ? "Watch Percentage" : "Watch Percentage",
            fill: false,
            data: response?.stateSubjectTopic?.z,
            borderWidth: 2,
            borderColor: 'rgba(0, 9, 128, 0.941)', 
            backgroundColor: 'rgba(0, 48, 170, 0.2)'
            // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

          dataArray2.push({
              type: 'bar',
              label: key === "Class Effort" ? "Average Open" : "Accuracy",
              fill: false,
              data: response?.stateSubjectTopic?.y,
              borderWidth: 2,
              borderColor: 'rgb(168, 90, 0)', 
              backgroundColor: 'rgba(255, 165, 62, 0.473)'
              // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
          });

       //---- For 3st chart - class Cognitive Rigor ----------------
       let dataArray3 = [];
        dataArray3.push({
        type: 'line',
        label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
        fill: false,
        data: response?.classCognitiveRigor?.z,
        borderWidth: 2,
        borderColor: 'rgba(0, 9, 128, 0.941)', 
        backgroundColor: 'rgba(0, 48, 170, 0.2)'
        // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

       dataArray3.push({
           type: 'bar',
           label: key === "Class Effort" ? "Average Open" : "Accuracy",
           fill: false,
           data: response?.classCognitiveRigor?.y,
           borderWidth: 2,
           borderColor: 'rgb(168, 90, 0)', 
           backgroundColor: 'rgba(255, 165, 62, 0.473)'
           // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
       });

        //---- For 4th chart - State Subject Topic ----------------
        let dataArray4 = [];

        dataArray4.push({
          type: 'line',
          label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
          fill: false,
          data: response?.stateCognitiveRigor?.z,
          borderWidth: 2,
          borderColor: 'rgba(0, 9, 128, 0.941)', 
          backgroundColor: 'rgba(0, 48, 170, 0.2)'
          // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
      });

        dataArray4.push({
            type: 'bar',
            label: key === "Class Effort" ? "Average Open" : "Accuracy",
            fill: false,
            data: response?.stateCognitiveRigor?.y,
            borderWidth: 2,
            borderColor: 'rgb(168, 90, 0)', 
            backgroundColor: 'rgba(255, 165, 62, 0.473)'
            // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

      
         //---- For 5th chart - Class Difficulty ----------------
         let dataArray5 = [];

         dataArray5.push({
          type: 'line',
          label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
          fill: false,
          data: response?.classDifficulty?.z,
          borderWidth: 2,
          borderColor: 'rgba(0, 9, 128, 0.941)', 
          backgroundColor: 'rgba(0, 48, 170, 0.2)'
          // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

         dataArray5.push({
             type: 'bar',
             label: key === "Class Effort" ? "Average Open" : "Accuracy",
             fill: false,
             data: response?.classDifficulty?.y,
             borderWidth: 2,
             borderColor: 'rgb(168, 90, 0)', 
             backgroundColor: 'rgba(255, 165, 62, 0.473)'
             // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
         });
 

      //---- For 6th chart - State Difficulty ----------------
            let dataArray6 = [];

              dataArray6.push({
                type: 'line',
                label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
                fill: false,
                data: response?.stateDifficulty?.z,
                borderWidth: 2,
                borderColor: 'rgba(0, 9, 128, 0.941)', 
                backgroundColor: 'rgba(0, 48, 170, 0.2)'
                // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
            });
  
              dataArray6.push({
                  type: 'bar',
                  label: key === "Class Effort" ? "Average Open" : "Accuracy",
                  fill: false,
                  data: response?.stateDifficulty?.y,
                  borderWidth: 2,
                  borderColor: 'rgb(168, 90, 0)', 
                  backgroundColor: 'rgba(255, 165, 62, 0.473)'
                  // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
              });

            if (key === "Class Effort") {
              setClassEffortCharts({
                classSubTopicData: dataArray,
                stateSubTopic: dataArray2,
                classCognitiveRigor: dataArray3,
                stateCognitiveRigor: dataArray4,
                classDifficulty: dataArray5,
                stateDifficulty: dataArray6
              });
            } else if (key === "Class Result") {

              // ------ for 7th Class result overallPerformance
                  let dataArray7 = [];
                  dataArray7.push({
                    type: 'doughnut',
                    label: "Percentage Watch",
                    fill: false,
                    data:response?.overAllPerformanceArr,
                    borderWidth: 2,
                    borderColor: ['rgba(255, 154, 13)', 'rgba(120, 120, 120, 0.5)'], 
                    backgroundColor: ['rgba(255, 192, 104)', 'rgba(196, 196, 196, 0.5)']
                  });

                  setClassResultCharts({
                    classSubTopicData: dataArray,
                    stateSubTopic: dataArray2,
                    classCognitiveRigor: dataArray3,
                    stateCognitiveRigor: dataArray4,
                    classDifficulty: dataArray5,
                    stateDifficulty: dataArray6,
                    overAllPerformance: dataArray7
                  });
          }
    }
  
  const icons = {
    Teacher : Teacher,
    Class : Class,
    School : School,
    ActualTime : ActualTime,
    Achievement : Achievement,
    Percentage : Percentage,
    GraphLoader : GraphLoader
  }

  // const charts = {
  //   overAllPerformance, classSubTopicData, stateSubTopic, classCognitiveRigor, stateCognitiveRigor,
  //   classDifficulty, stateDifficulty
  // }

  return (
    <div id="teacherDash" className='a4 TeacherDashboard px-4'>

        <div className='title d-flex flex-column flex-md-row justify-content-center align-items-center'>
          <img src={Madam} alt="Teacher Dashboard" width={50} height={50}/>
          <h1>TEACHER ANALYTICS</h1>
        </div>

        {
            loader ? <h2 className='text-center my-4'>Loading ...</h2> : 
            <React.Fragment>
          <div className='titleHeader'>
            <h5><Icon.BriefcaseFill className="me-2" />Class Efforts</h5>
          </div>

       <TeacherEffort data={effortData} icons={icons} charts={classEffortCharts} activeKey="Class Effort"/>

      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
       <div className='titleHeader'>
          <h5><Icon.AwardFill className="me-2" />Class Result</h5>
        </div>

        <TeacherEffort data={resultData} icons={icons} charts={classResultCharts} activeKey="Class Result"/>

            </React.Fragment>
        }
    </div>
  )
}


export default TeacherDashFull;