// src/utils/pdfUtils.js
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const generatePdfFromCharts = async (chartIds, fileName) => {
  const pdf = new jsPDF();
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  // Ensure chartIds is an array
  const ids = Array.isArray(chartIds) ? chartIds : [chartIds];

  for (let i = 0; i < ids.length; i++) {
    const chartElement = document.getElementById(ids[i]);
    if (!chartElement) {
      console.error(`Element with ID ${ids[i]} not found.`);
      continue;
    }

    const canvas = await html2canvas(chartElement);
    const imgData = canvas.toDataURL('image/png');

    if (i > 0) {
      pdf.addPage();
    }

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
  }

  pdf.save(`${fileName}.pdf`);
};

export default generatePdfFromCharts;
