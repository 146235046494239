import React from 'react';
import PageNotFoundImg from '../Assets/404.webp';
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='PageNotFound'>
      <Container>
        <Row className='justify-content-center' data-aos="fade-up">
          <Col md={10} className='text-center'>
            <img src={PageNotFoundImg} className='img-fluid' alt="Page Not Found"/>
            <h1>Page Not Found</h1>
            <p>* Please, visit with valid link or route</p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={6} className='d-flex flex-column flex-md-row justify-content-between align-items-center mb-3'>
              <Button onClick={()=>navigate('/teacher/14/Grade%206/GHANTAPADA%20HIGH%20SCHOOL./')} className='mb-4 mb-md-0 viewBtn'> 
                <Icon.PersonVcard className="me-2"/>View Example Teacher Analytics</Button>
              <Button onClick={()=>navigate('/student/8')} className='viewBtn'>
                <Icon.PersonVcard className='me-2'/>View Example Student Analytics</Button>
          </Col>
        </Row>
        </Container>
    </div>
  )
}

export default PageNotFound