import React from 'react';
import {Modal} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

const ChartModal = ({open, close, data}) => {
  return (
    <div>
        {/* -------- Modal For Showing Clicked Data on chart Points ----- */}
        <Modal show={open} onHide={close}  centered keyboard={false}  backdrop="static" className='modal'>
        <Modal.Header closeButton>
          <Modal.Title><h5><Icon.HandIndex className='me-2'/>Your Click</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex'>
            <h5><strong>Label:<Icon.ArrowRight className='mx-2'/></strong></h5>
            <h5> {data.label}</h5>
          </div>
          <div className='d-flex'>
            <h5><strong>Data Label:<Icon.ArrowRight className='mx-2'/></strong></h5>
            <h5>{data.dataLabel}</h5>
          </div>
          <div className='d-flex'>
            <h5><strong>Value:<Icon.ArrowRight className='mx-2'/></strong></h5>
            <h5>{data.value}</h5>
          </div>

        </Modal.Body>
        
      </Modal>
    </div>
  )
}

export default ChartModal;