import { BrowserRouter, Routes, Route } from "react-router-dom";
import TeacherDashboard from "./Components/Teacher/TeacherDashboard";
import ScrollToTop from './Common/ScrollToTop';
import PageNotFound from './Common/PageNotFound';
import StudentDashboard from "./Components/Student/StudentDashboard";
import PrincipleDashborad from "./Components/principleDashboard";
import TeacherDashFull from "./Components/Teacher/TeacherDashFull";


function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/admin" element={<PrincipleDashborad />} />
      <Route path="/teacher/:teacherId/:className/:schoolName" element={<TeacherDashboard />} />
      <Route path="/teacherReport/:teacherId/:className/:schoolName" element={<TeacherDashFull />} />
      <Route path="/student/:studentId" element={<StudentDashboard />} />
      <Route path='*' element={<PageNotFound/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
