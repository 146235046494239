import React, { useState } from "react";
import { Card } from "react-bootstrap";
import ChartModal from './ChartModal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart as ChartJS} from 'chart.js/auto';
import { Chart } from "react-chartjs-2";

const Chartjs = ({ chartLabels, chartData, stacked, chartHeading, height, onBarClick, isModal}) => {
  // const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   if (chartData !== undefined && chartData.length > 0) {
  //     setLoader(false);
  //   } else {
  //     setLoader(true);
  //   }
  // }, [chartData]);

    const [show, setShow] = useState(false);

  /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
    const [label, setLabel] = useState();
    const [dataLabel, setDataLabel] = useState();
    const [value, setValue] = useState();

  return (
    <>
      <Card className="chartCard" style={{ height: height, padding: "20px 20px 40px 20px" }}>
        <h6 className="text-center">{chartHeading}</h6>
          <Chart
            data={{
              labels: chartLabels,
              fill: false,
              datasets: chartData?.map((row) => {
                return {
                  type: row?.type,
                  label: row?.label,
                  data: row?.data,
                  fill: false,
                  borderWidth: row?.borderWidth,
                  borderColor:row?.borderColor,
                  backgroundColor: row?.backgroundColor,
                  hoverOffset: 10
                };
              }),
            }}

            
            plugins={[ChartDataLabels,  {align: "end"}]}
                options= {{
                  plugins: {
                    datalabels: {
                      display: true,
                      color: '#808080',
                      formatter: Math.round,
                      anchor: "end",
                      margin:'20px',
                      offset: -15,
                      align: "start",
                      font: {
                            weight: '500',
                            size:'8px'
                          },
                    },
                  },
                  legend: {
                    display: false
                  },
                  interaction: {
                    intersect: false,
                  }, 
                  maintainAspectRatio: false,
                  responsive: true,
                 scales: stacked
                 ? {
                     x: { stacked: true },
                     y: { stacked: true }
                   }
                 : undefined,
              onClick: (e, element) => {
                // console.log("Whole E", e);
                // console.log("Element", element);
                // console.log("Label is : ", e.chart.data.labels[element[0].index]);
                // console.log("Index", element[0].index);
                let index = element[0].index;
                // let dataindex = element[0].datasetIndex;

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                 return arr.push(row.data[index]);
                });

                // console.log("Onclick Array", arr);
                // console.log("first", arr[element[0].datasetIndex]);
                // console.log("Data set Array", e.chart.config._config.data.datasets[element[0].datasetIndex].label);


                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(isModal);
                if(onBarClick){
                  onBarClick({ label:e.chart.data.labels[element[0].index], 
                    dataLabel: e.chart.config._config.data.datasets[element[0].datasetIndex].label, 
                    value:arr[element[0].datasetIndex] });
                }
                // if(index!==undefined && index!==""){
                //   console.log("Data set Array", e.chart.config._config.data.datasets[index].label)
                // }
              },
            }}
          />
      </Card>

      <ChartModal open={show} close={() => setShow(false)} data={{ label, dataLabel, value }} />
    </>
  );
};

export default Chartjs;
