import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import GaugeChart from '../../Common/GaugeChart';
import Chartjs from '../../Common/Chartjs';
import StudentEffortReport from '../StudentEffortReport';
import StudentResultReport from '../StudentResultReport';

const TeacherEffort = ({ data, icons, charts, activeKey }) => {

    // console.log("charts", charts);
    // activeKey === "Class Effort" ? console.log("Effort Prop",data) : console.log("result Prop", data)

  return (
    <div className='TeacherEffort'>
        <Row className='Info gx-5'>
          <Col md={5} className='mt-3 mt-md-0' data-aos="fade-up" data-aos-delay="100">
            <div className="Left">
            <p className='headline'><Icon.PersonFill className='me-2'/>Teacher Information</p>
                <Card>
                  <img src={icons.Teacher} alt="Teacher"/>
                  <div className='body'>
                    <h5>{ data?.TeacherName}</h5>
                    <small className="text-muted">Teacher Name</small>
                  </div>
                </Card>

                <Card>
                  <img src={icons.Class} alt="Class"/>
                  <div className='body'>
                    <h5>{data?.Class}</h5>
                    <small className="text-muted">Class</small>
                  </div>
                </Card>

                <Card>
                  <img src={icons.School} alt="School"/>
                  <div className='body'>
                    <h5>{data?.School}</h5>
                    <small className="text-muted">School Name</small>
                  </div>
                </Card>

            </div>
          </Col>


          <Col md={activeKey === "Class Effort" ? 5: 4} className='mt-5 mt-md-0' data-aos="fade-up" data-aos-delay="200">
            <div className='Right'>
            <p className='headline'><Icon.ClockFill className='me-2'/>Content Watch Summary</p>
              <Card>
                  <img src={icons.ActualTime} alt="Actual Duration"/>
                  <div className='body'>
                    <h5>{activeKey === "Class Effort" ? data?.sumDurationInSeconds : data?.totalExams}</h5>
                    <small className="text-muted">{ activeKey === "Class Effort" ? "Actual Content Duaration (Hr)" : "Total Exam" }</small>
                  </div>
                </Card>

                <Card>
                  <img src={icons.Achievement} alt="Total Watch Duration"/>
                  <div className='body'>
                    <h5>{ activeKey === "Class Effort" ? data?.sumWatchDurationInSeconds : data?.totalStudents}</h5>
                    <small className="text-muted">{ activeKey === "Class Effort" ? "Total Content Watch Duration (Hr)" : "No. Of Students Attempted" }</small>
                  </div>
                </Card>

                <Card>
                  <img src={icons.Percentage} alt="Percentage Watch"/>
                  <div className='body'>
                    <h5>{activeKey === "Class Effort" ? data?.percentageWatch :  data?.accuracy}</h5>
                    <small className="text-muted">{ activeKey === "Class Effort" ?  "Content Watch Percentage" : "Accuracy"}</small>
                  </div>
                </Card>
            </div>
          </Col>

          {
            activeKey === "Class Result" && 
              <Col md={3} className='d-flex justify-content-center align-items-center mt-5 mt-md-0' data-aos="fade-up" data-aos-delay="300">
                   <Card className='mt-0 mt-md-4' style={{ padding:'30px 20px 40px 20px',
                      border:'2px solid #aad7ff', borderRadius:'10px', display:'flex', flexDirection:'column', maxHeight:'230px',
                  }}>
                    <p className='headline'><Icon.AwardFill className='me-2'/>Result</p>
                    <h6 className='text-center'>Overall Watch Performance</h6>
                   {
                      charts.overAllPerformance!==null  && charts.overAllPerformance !== undefined 
                      && charts.overAllPerformance?.length > 0 ?
                      <GaugeChart chartLabels='Overall Performance' chartData={charts.overAllPerformance}  
                            stacked={false} chartHeading="Overall Performance" height='300px' 
                            isPlugin={false} isModal={false}/> :
                        <div className='d-flex justify-content-center align-items-center h-100' 
                             style={{width:'150px', height:'150px'}}>
                          <img src={icons.GraphLoader} alt="Loader" style={{width:'100%', height:'100%'}} />
                        </div>
                  }
                  </Card>
              </Col>
            }
        </Row>

        <div className='subHeader w-50 mx-auto mt-5 mb-4'>
          <h5><Icon.BookFill className="me-2" />Subject Report</h5>
        </div>

        <Row>
            <Col md={4} className='mb-5 mb-md-0'>
                    {
                      charts.classSubTopicData !== null && charts.classSubTopicData !== undefined && charts.classSubTopicData?.length > 0 ?
                        <Chartjs chartLabels={data?.classSubjectTopic?.x} chartData={charts.classSubTopicData}  stacked={false}
                          chartHeading={activeKey==="Class Effort" ? "Class Avg. Content Watch Analytics" : "Class Avg. Assessment Analytics"} height='350px' isPlugin={false} isModal={false}/> :
                            <div className='d-flex justify-content-center align-items-center h-100'>
                              <img src={icons.GraphLoader} alt="Loader" width={200} />
                            </div>
                    }
            </Col>

            <Col md={8}>
              {
                charts.stateSubTopic !== null && charts.stateSubTopic !== undefined && charts.stateSubTopic?.length > 0 ?
                  <Chartjs chartLabels={data?.stateSubjectTopic?.x} chartData={charts.stateSubTopic}  stacked={false}
                  chartHeading={activeKey==="Class Effort" ? "State Avg. Content Watch Analytics" : "State Avg. Assessment Analytics"} height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={icons.GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>
        </Row>

        <div className='subHeader w-50 mx-auto mt-5 mb-4'>
          <h5><Icon.GridFill className="me-2" />Bloom's Taxonomy</h5>
        </div>

        <Row>    
            <Col md={6} className='mb-5 mb-md-0'>
              {
                charts.classCognitiveRigor !== null && charts.classCognitiveRigor !== undefined && charts.classCognitiveRigor?.length > 0 ?
                  <Chartjs chartLabels={data?.classCognitiveRigor?.x} chartData={charts.classCognitiveRigor}  stacked={false}
                  chartHeading={activeKey==="Class Effort" ? "Class Bloom's Taxonomy" : "Class Bloom's Taxonomy"} height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={icons.GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>


            <Col md={6}>
              {
                charts.stateCognitiveRigor !== null && charts.stateCognitiveRigor !== undefined && charts.stateCognitiveRigor?.length > 0 ?
                  <Chartjs chartLabels={data?.stateCognitiveRigor?.x} chartData={charts.stateCognitiveRigor}  stacked={false}
                    chartHeading={activeKey==="Class Effort" ? "State Bloom's Taxonomy" : "State Bloom's Taxonomy"} height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={icons.GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>
        </Row>

        <div className='subHeader w-50 mx-auto mt-5 mb-4'>
          <h5><Icon.BarChartFill className="me-2" />Difficulty</h5>
        </div>

        <Row>
                <Col md={6} className='mb-5 mb-md-0'>
                  {
                    charts.classDifficulty !== null && charts.classDifficulty !== undefined && charts.classDifficulty?.length > 0 ?
                      <Chartjs chartLabels={data?.classDifficulty?.x} chartData={charts.classDifficulty}  stacked={false}
                        chartHeading="Class Difficulty" height='350px' isPlugin={false} isModal={false}/> :
                          <div className='d-flex justify-content-center align-items-center h-100'>
                            <img src={icons.GraphLoader} alt="Loader" width={200} />
                          </div>
                  }
              </Col>

                <Col md={6}>
                  {
                    charts.stateDifficulty !== null && charts.stateDifficulty !== undefined && charts.stateDifficulty?.length > 0 ?
                      <Chartjs chartLabels={data?.stateDifficulty?.x} chartData={charts.stateDifficulty}  stacked={false}
                        chartHeading="State Difficulty" height='350px' isPlugin={false} isModal={false}/> :
                          <div className='d-flex justify-content-center align-items-center h-100'>
                            <img src={icons.GraphLoader} alt="Loader" width={200} />
                          </div>
                  }
                </Col>
              </Row>

        <div className='subHeader w-50 mx-auto mt-5 mb-4'>
          <h5><Icon.BarChartFill className="me-2" />Student Report</h5>
        </div>

            {
                activeKey === "Class Effort" ? 
                  <StudentEffortReport/> :
                  <StudentResultReport/>
                }




    </div>
  )
}

export default TeacherEffort;