import React, { useState } from "react";
import { Card } from "react-bootstrap";
import ChartModal from './ChartModal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart as ChartJS} from 'chart.js/auto';
import { Chart } from "react-chartjs-2";

const GaugeChart = ({ chartLabels, chartData, stacked, chartHeading, height, onBarClick, isModal}) => {
  // const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   if (chartData !== undefined && chartData.length > 0) {
  //     setLoader(false);
  //   } else {
  //     setLoader(true);
  //   }
  // }, [chartData]);

    const [show, setShow] = useState(false);

  /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
    const [label, setLabel] = useState();
    const [dataLabel, setDataLabel] = useState();
    const [value, setValue] = useState();

  return (
    <>
          <Chart
            data={{
              labels: chartLabels,
              fill: false,
              datasets: chartData?.map((row) => {
                return {
                  type: row?.type,
                  label: row?.label,
                  data: row?.data,
                  fill: false,
                  borderWidth: row?.borderWidth,
                  borderColor:row?.borderColor,
                  backgroundColor: row?.backgroundColor,
                  hoverOffset: 10
                };
              }),
            }}

            
            plugins={[ChartDataLabels]}
        options={{
          plugins: {
            datalabels: {
              display: true,
              color: '#808080',
              formatter: Math.round + '%',
              font: {
                weight: 'bold'
              }
            },
          },
          cutout: '70%', // Adjust as needed to control the size of the gauge
          rotation: 270,  // Rotate to make it a half gauge
          circumference: 180, // Make it a half gauge
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          },
        }}
          />

      <ChartModal open={show} close={() => setShow(false)} data={{ label, dataLabel, value }} />
    </>
  );
};

export default GaugeChart;
