import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Tab, Nav, Form, Button} from 'react-bootstrap';
import Madam from '../Assets/Madam.svg';
import Teacher from '../Assets/Teacher.svg';
import Chartjs from '../Common/Chartjs';
import GraphLoader from '../Assets/GraphLoader.gif';
import GaugeChart from '../Common/GaugeChart';
import * as Icon from 'react-bootstrap-icons';
import Class from '../Assets/Class.svg';
import School from '../Assets/School.svg';
import ActualTime from '../Assets/ActualTime.svg';
import Achievement from '../Assets/Achievement.svg';
import Percentage from '../Assets/Percentage.svg';
import StudentEffortReport from './StudentEffortReport';
import StudentResultReport from './StudentResultReport';
import adminEffort from './AdminEffort.json';
import adminResult from './AdminResult.json';
import Student from '../Assets/Student.svg';
import generatePdfFromCharts from '../Utils/ExportPDF';


const PrincipleDashborad = () => {


 
  const [data, setData] = useState(adminEffort);
  const [dataResult, setDataResult] = useState(adminResult);


  const [loader, setLoader] = useState(false);

  const [key, setKey] = useState('Class Effort');
  const [key2, setKey2] = useState('Subject');

  const [classSubTopicData, setClassSubTopicData] = useState('');
  const [stateSubTopic, setStateSubTopic] = useState('');
  const [classCognitiveRigor, setClassCognitiveRigor] = useState('');
  const [stateCognitiveRigor, setStateCognitiveRigor] = useState('');
  const [classDifficulty, setClassDifficulty] = useState('');
  const [stateDifficulty, setStateDifficulty] = useState('');
  const [schoolReport, setSchoolReport] = useState('');
  const [overAllPerformance, setOverAllPerformance] = useState('');




  useEffect(()=>{
    console.log("Calling");
    getContentWatchTimeReport();
  },[key])

  


  const getContentWatchTimeReport = async()=>{
      setLoader(true);

    
     
    //   await fetch(process.env.REACT_APP_BASE_URL + '/cwtr/ContentWatchTimeReportList',{
    //     method:'POST',
    //     headers:{'Content-Type':'application/json'},
    //     body:JSON.stringify({ teacherId: teacherId, className: className,
    //                           schoolName: schoolName,
    //                           type: key // ["Class Effort", "Class Result"]})
    //     })
    // }).then((res)=>res.json()).then((response)=>{
    //   console.log("response", response);
    //   setData(response);

      //---- For 1st chart - Class Subject Topic ----------------
            let dataArray = [];
                  dataArray.push({
                    type: 'line',
                    label: key === "Class Effort" ? "Watch Percentage" : "Avg. Response time",
                    fill: false,
                    data: key==="Class Effort" ? data?.classSubjectTopic?.z : dataResult?.classSubjectTopic?.z,
                    borderWidth: 2,
                    borderColor: 'rgba(0, 9, 128, 0.941)', 
                    backgroundColor: 'rgba(0, 48, 170, 0.2)'
                    // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
                });

                dataArray.push({
                    type: 'bar',
                    label: key === "Class Effort" ? "Average Open" : "Accuracy",
                    fill: false,
                    data: key==="Class Effort" ? data?.classSubjectTopic?.y : dataResult?.classSubjectTopic?.y,
                    borderWidth: 2,
                    borderColor: 'rgb(168, 90, 0)', 
                    backgroundColor: 'rgba(255, 165, 62, 0.473)'
                    // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
                });

               
                setClassSubTopicData(dataArray);

      //---- For 2st chart - State Subject Topic ----------------
            let dataArray2 = [];
            dataArray2.push({
              type: 'line',
              label: key === "Class Effort" ? "Watch Percentage" : "Avg. Response time",
              fill: false,
              data: key==="Class Effort" ? data?.stateSubjectTopic?.z : dataResult?.stateSubjectTopic?.z,
              borderWidth: 2,
              borderColor: 'rgba(0, 9, 128, 0.941)', 
              backgroundColor: 'rgba(0, 48, 170, 0.2)'
              // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
          });

            dataArray2.push({
                type: 'bar',
                label: key === "Class Effort" ? "Average Open" : "Accuracy",
                fill: false,
                data: key==="Class Effort" ? data?.stateSubjectTopic?.y : dataResult?.stateSubjectTopic?.y,
                borderWidth: 2,
                borderColor: 'rgb(168, 90, 0)', 
                backgroundColor: 'rgba(255, 165, 62, 0.473)'
                // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
            });

         
            setStateSubTopic(dataArray2);


       //---- For 3st chart - class Cognitive Rigor ----------------
       let dataArray3 = [];
      
       dataArray3.push({
        type: 'line',
        label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
        fill: false,
        data: key==="Class Effort" ? data?.classCognitiveRigor?.z : dataResult?.classCognitiveRigor?.z,
        borderWidth: 2,
        borderColor: 'rgba(0, 9, 128, 0.941)', 
        backgroundColor: 'rgba(0, 48, 170, 0.2)'
        // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
    });

       dataArray3.push({
           type: 'bar',
           label: key === "Class Effort" ? "Average Open" : "Accuracy",
           fill: false,
           data: key==="Class Effort" ? data?.classCognitiveRigor?.y : dataResult?.classCognitiveRigor?.y,
           borderWidth: 2,
           borderColor: 'rgb(168, 90, 0)', 
           backgroundColor: 'rgba(255, 165, 62, 0.473)'
           // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
       });

       setClassCognitiveRigor(dataArray3);


    //---- For 4th chart - State Subject Topic ----------------
        let dataArray4 = [];

        dataArray4.push({
          type: 'line',
          label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
          fill: false,
          data: key==="Class Effort" ? data?.stateCognitiveRigor?.z : dataResult?.stateCognitiveRigor?.z,
          borderWidth: 2,
          borderColor: 'rgba(0, 9, 128, 0.941)', 
          backgroundColor: 'rgba(0, 48, 170, 0.2)'
          // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
      });

        dataArray4.push({
            type: 'bar',
            label: key === "Class Effort" ? "Average Open" : "Accuracy",
            fill: false,
            data: key==="Class Effort" ? data?.stateCognitiveRigor?.y : dataResult?.stateCognitiveRigor?.y,
            borderWidth: 2,
            borderColor: 'rgb(168, 90, 0)', 
            backgroundColor: 'rgba(255, 165, 62, 0.473)'
            // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

       
        setStateCognitiveRigor(dataArray4);


         //---- For 5th chart - Class Difficulty ----------------
         let dataArray5 = [];

         dataArray5.push({
          type: 'line',
          label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
          fill: false,
          data: key==="Class Effort" ? data?.classDifficulty?.z : dataResult?.classDifficulty?.z,
          borderWidth: 2,
          borderColor: 'rgba(0, 9, 128, 0.941)', 
          backgroundColor: 'rgba(0, 48, 170, 0.2)'
          // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
      });

         dataArray5.push({
             type: 'bar',
             label: key === "Class Effort" ? "Average Open" : "Accuracy",
             fill: false,
             data: key==="Class Effort" ? data?.classDifficulty?.y : dataResult?.classDifficulty?.y,
             borderWidth: 2,
             borderColor: 'rgb(168, 90, 0)', 
             backgroundColor: 'rgba(255, 165, 62, 0.473)'
             // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
         });
 
        
         setClassDifficulty(dataArray5);


        //---- For 6th chart - State Difficulty ----------------
            let dataArray6 = [];

            dataArray6.push({
              type: 'line',
              label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
              fill: false,
              data: key==="Class Effort" ? data?.stateDifficulty?.z : dataResult?.stateDifficulty?.z,
              borderWidth: 2,
              borderColor: 'rgba(0, 9, 128, 0.941)', 
              backgroundColor: 'rgba(0, 48, 170, 0.2)'
              // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
          });

            dataArray6.push({
                type: 'bar',
                label: key === "Class Effort" ? "Average Open" : "Accuracy",
                fill: false,
                data: key==="Class Effort" ? data?.stateDifficulty?.y : dataResult?.stateDifficulty?.y,
                borderWidth: 2,
                borderColor: 'rgb(168, 90, 0)', 
                backgroundColor: 'rgba(255, 165, 62, 0.473)'
                // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
            });
    
            
            setStateDifficulty(dataArray6);


            /* -------- new Schol Repot -------------*/ 
            
        //---- For 6th chart - State Difficulty ----------------
        let dataArray8 = [];

        dataArray8.push({
          type: 'line',
          label: key === "Class Effort" ? "Watch Percentage" : "Average Reposne Time (sec)",
          fill: false,
          data: data?.schoolReport?.z,
          borderWidth: 2,
          borderColor: 'rgba(0, 9, 128, 0.941)', 
          backgroundColor: 'rgba(0, 48, 170, 0.2)'
          // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
      });

        dataArray8.push({
            type: 'bar',
            label: key === "Class Effort" ? "Average Open" : "Accuracy",
            fill: false,
            data: data?.schoolReport?.y,
            borderWidth: 2,
            borderColor: 'rgb(168, 90, 0)', 
            backgroundColor: 'rgba(255, 165, 62, 0.473)'
            // backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
        });

        setSchoolReport(dataArray8);

            if(key==="Class Result"){
              let dataArray7 = [];
              dataArray7.push({
                type: 'doughnut',
                label: "Percentage Watch",
                fill: false,
                data:dataResult?.overAllPerformanceArr,
                borderWidth: 2,
                borderColor: ['rgba(255, 154, 13)', 'rgba(120, 120, 120, 0.5)'], 
                backgroundColor: ['rgba(255, 192, 104)', 'rgba(196, 196, 196, 0.5)']
              });

              setOverAllPerformance(dataArray7);
            }
      setLoader(false);
  }
  


  
 
  return (
    <div id="principalDash" className='TeacherDashboard'>
      <Container>
        <div className='title d-flex flex-column flex-md-row justify-content-center align-items-center'>
          <img src={Madam} alt="Teacher Dashboard" width={50} height={50}/>
          <h1>ADMIN ANALYTICS</h1>
        </div>

        <Row className='justify-content-between mt-3'>
            <Col md={2}>
                  <Button id='primaryBtn' onClick={()=>generatePdfFromCharts('principalDash', "Admin_Report")}>
                  <Icon.FiletypePdf className='me-2'/>Export Report</Button>
              </Col>
          <Col md={3}>
          <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
            <Nav variant="pills" className="flex-row justify-content-center ms-auto">
                <Nav.Item>
                  <Nav.Link eventKey='Class Effort'>
                    <Icon.BriefcaseFill className="me-2" />State Efforts</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='Class Result'>
                    <Icon.AwardFill className='me-2'/>State Results</Nav.Link>
                </Nav.Item>
            </Nav>
          </Tab.Container>
          </Col>
        </Row>
        

        
        <Row className='Info gx-5'>
         
          <Col md={5} className='mt-3 mt-md-0' data-aos="fade-up" data-aos-delay="100">
            <div className="Left">
            <p className='headline'><Icon.PersonFill className='me-2'/>State Information</p>
            {
            loader ? <p>Loading...</p> :
              <Row> 
                <Col md={6}>
                  <Card>
                    <img src={Teacher} alt="Teacher"/>
                    <div className='body'>
                      <h5>{ key ==="class Effort" ? data?.adminName : adminResult?.adminName}</h5>
                      <small className="text-muted">Admin Name</small>
                    </div>
                  </Card>
                

                <Card>
                  <img src={Class} alt="Class"/>
                  <div className='body'>
                    <h5>{ key ==="class Effort" ? data?.totalClass : adminResult?.totalClass}</h5>
                    <small className="text-muted">Total Classes</small>
                  </div>
                </Card>

                <Card>
                  <img src={School} alt="School"/>
                  <div className='body'>
                    <h5>{ key ==="class Effort" ? data?.totalSchools : adminResult?.totalSchools}</h5>
                    <small className="text-muted">Total Schools</small>
                  </div>
                </Card>
                </Col>


                <Col md={6}>
                <Card>
                  <img src={Madam} alt="School"/>
                  <div className='body'>
                    <h5>{ key ==="class Effort" ? data?.TotalTeachers : adminResult?.TotalTeachers}</h5>
                    <small className="text-muted">Total Teachers</small>
                  </div>
                </Card>

                <Card>
                  <img src={Student} alt="School"/>
                  <div className='body'>
                    <h5>{ key ==="class Effort" ? data?.TotalStudents : adminResult?.TotalStudents}</h5>
                    <small className="text-muted">Total Students</small>
                  </div>
                </Card>
                </Col>
              </Row>
            }
            </div>
          </Col>



          <Col md={key==="Class Effort" ? 5 : 4} className='mt-5 mt-md-0' data-aos="fade-up" data-aos-delay="200">
            <div className='Right'>
            <p className='headline'><Icon.ClockFill className='me-2'/>Content Watch Summary</p>
            {
            loader ? <p>Loading...</p> :
            <>
              <Card>
                  <img src={ActualTime} alt="Actual Duration"/>
                  <div className='body'>
                    <h5>{key === "Class Effort" ? data?.ActiveStudents : dataResult?.TotalExam}</h5>
                    <small className="text-muted">{ key === "Class Effort" ? "Active Students" : "Total Exam" }</small>
                  </div>
                </Card>

                <Card>
                  <img src={Achievement} alt="Total Watch Duration"/>
                  <div className='body'>
                    <h5>{ key === "Class Effort" ? data?.OverallEffort : dataResult?.OverallResult}</h5>
                    <small className="text-muted">{ key === "Class Effort" ? "Overall Effort" : "Overall Result" }</small>
                  </div>
                </Card>

                <Card>
                  <img src={Percentage} alt="Percentage Watch"/>
                  <div className='body'>
                    <h5>{key === "Class Effort" ? data?.CurrentWeekEffort :  dataResult?.CurrentWeekResult}</h5>
                    <small className="text-muted">{ key === "Class Effort" ?  "Current Week Effort" : "Current Week Result"}</small>
                  </div>
                </Card>
                </>
        }
            </div>
          </Col>

          {
            key === "Class Result" && 
              <Col md={3} className='d-flex justify-content-center align-items-center mt-5 mt-md-0' data-aos="fade-up" data-aos-delay="300">
                   <Card className='mt-0 mt-md-4' style={{ padding:'30px 20px 40px 20px',
                      border:'2px solid #aad7ff', borderRadius:'10px', display:'flex', flexDirection:'column', maxHeight:'230px',
                  }}>
                    <p className='headline'><Icon.AwardFill className='me-2'/>Result</p>
                    <h6 className='text-center'>Overall Watch Performance</h6>
                   {
                      overAllPerformance!==null  && overAllPerformance !== undefined 
                      && overAllPerformance?.length > 0 ?
                      <GaugeChart chartLabels='Overall Performance' chartData={overAllPerformance}  
                            stacked={false} chartHeading="Overall Performance" height='300px' 
                            isPlugin={false} isModal={false}/> :
                        <div className='d-flex justify-content-center align-items-center h-100' 
                             style={{width:'150px', height:'150px'}}>
                          <img src={GraphLoader} alt="Loader" style={{width:'100%', height:'100%'}} />
                        </div>
                  }
                  </Card>
              </Col>
          }
        </Row>

       
       {
        key === "Class Effort" &&
        <Row className='my-4 justify-content-center'>
          <Col md={8}>
          {
                 schoolReport !== null && schoolReport !== undefined && schoolReport?.length > 0 ?
                        <Chartjs chartLabels={data?.schoolReport?.x} chartData={schoolReport}  stacked={false}
                          chartHeading={key==="Class Effort" ? "All School Effort Analytics" : "Class Avg. Assessment Analytics"} height='350px' isPlugin={false} isModal={false}/> :
                            <div className='d-flex justify-content-center align-items-center h-100'>
                              <img src={GraphLoader} alt="Loader" width={200} />
                            </div>
                    }
          </Col>
       </Row>
    }


        <Tab.Container activeKey={key2} onSelect={(k) => setKey2(k)}>
          <Row className='mt-5 px-3 justify-content-center'>
            <Col md="auto" lg="max-content" className='d-flex justify-content-center'>
            <Nav variant="pills" className="flex-column flex-md-row justify-content-center w-100" >
                <Nav.Item>
                  <Nav.Link eventKey='Subject'> 
                  { key2==="Subject" ? 
                    <Icon.BookFill className='me-2'/>:<Icon.Book className='me-2'/>
                   }
                    Subject Report</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='Blooms'>
                    {key2==="Blooms" ? <Icon.GridFill className='me-2'/> :  <Icon.Grid className='me-2'/> }
                    Bloom's Taxonomy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='Difficulty'>
                   { key2 === "Difficulty" ? <Icon.BarChartFill className="me-2"/>:
                    <Icon.BarChart className='me-2'/> } Difficulty Report</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey='Student'>
                    { key2 === "Student" ? <Icon.PersonFill className='me-2'/> : 
                    <Icon.Person className='me-2'/>} Student Report</Nav.Link>
                </Nav.Item> */}
            </Nav>
            </Col>
            
            </Row>

            <Tab.Content className='mt-4'>
                <Tab.Pane eventKey="Subject">
                  <Row>
                  <Col md={4} className='mb-5 mb-md-0'>
                    {
                      classSubTopicData !== null && classSubTopicData !== undefined && classSubTopicData?.length > 0 ?
                        <Chartjs chartLabels={data?.classSubjectTopic?.x} chartData={classSubTopicData}  stacked={false}
                          chartHeading={key==="Class Effort" ? "Class Avg. Content Watch Analytics" : "Class Avg. Assessment Analytics"} height='350px' isPlugin={false} isModal={false}/> :
                            <div className='d-flex justify-content-center align-items-center h-100'>
                              <img src={GraphLoader} alt="Loader" width={200} />
                            </div>
                    }
                </Col>

            <Col md={8}>
              {
                stateSubTopic !== null && stateSubTopic !== undefined && stateSubTopic?.length > 0 ?
                  <Chartjs chartLabels={data?.stateSubjectTopic?.x} chartData={stateSubTopic}  stacked={false}
                  chartHeading={key==="Class Effort" ? "State Avg. Content Watch Analytics" : "State Avg. Assessment Analytics"} height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>

                  </Row>
                </Tab.Pane>

                {/* ------------------ Bloom's Congnitive ---------------- */}
                <Tab.Pane eventKey="Blooms">
                  <Row>
                    
            <Col md={6} className='mb-5'>
              {
                classCognitiveRigor !== null && classCognitiveRigor !== undefined && classCognitiveRigor?.length > 0 ?
                  <Chartjs chartLabels={data?.classCognitiveRigor?.x} chartData={classCognitiveRigor}  stacked={false}
                  chartHeading={key==="Class Effort" ? "Class Bloom's Taxonomy" : "Class Bloom's Taxonomy"} height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>


            <Col md={6}>
              {
                stateCognitiveRigor !== null && stateCognitiveRigor !== undefined && stateCognitiveRigor?.length > 0 ?
                  <Chartjs chartLabels={data?.stateCognitiveRigor?.x} chartData={stateCognitiveRigor}  stacked={false}
                    chartHeading={key==="Class Effort" ? "State Bloom's Taxonomy" : "State Bloom's Taxonomy"} height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>
                  </Row>
                </Tab.Pane>
           

            {/* ------------- Difficulty Level Report --------- */}
            <Tab.Pane eventKey="Difficulty">
              <Row>
              <Col md={6} className='mb-5'>
              {
                classDifficulty !== null && classDifficulty !== undefined && classDifficulty?.length > 0 ?
                  <Chartjs chartLabels={data?.classDifficulty?.x} chartData={classDifficulty}  stacked={false}
                    chartHeading="Class Difficulty" height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>


            <Col md={6}>
              {
                stateDifficulty !== null && stateDifficulty !== undefined && stateDifficulty?.length > 0 ?
                  <Chartjs chartLabels={data?.stateDifficulty?.x} chartData={stateDifficulty}  stacked={false}
                    chartHeading="State Difficulty" height='350px' isPlugin={false} isModal={false}/> :
                      <div className='d-flex justify-content-center align-items-center h-100'>
                        <img src={GraphLoader} alt="Loader" width={200} />
                      </div>
              }
            </Col>
              </Row>
            </Tab.Pane>

            {/* ------------- Difficulty Level Report --------- */}
            <Tab.Pane eventKey="Student">
                {
                  key === "Class Effort" ? 
                  <StudentEffortReport/> :
                  <StudentResultReport/>
                }

            </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
      </Container>

      {/* <div className='lenevo mt-4 px-5'>
        <div className='card1'>
          <h1>New</h1>
        </div>

        <div className='card2 mt-3'>
          <h1>New</h1>
        </div>
      </div> */}
    </div>
  )
}


export default PrincipleDashborad;