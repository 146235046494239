import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Skeleton from '../Common/Skeleton';

const StudentEffortReport = () => {

    const { teacherId, className, schoolName} = useParams();

    const [tableLoader, setTableLoader] = useState(false);
    const [studentListByEffort, setStudentListByEffort] = useState([]);
    const [studentId, setStudentId] = useState('All');
    const [studentEffortReport, setStudentEffortReport] = useState([]);

    useEffect(()=>{
        getStudentListByEffort();
      },[])


    useEffect(()=>{
        if (studentId) {
            getStudentEffortReport();
        }
      },[studentId])


    const getStudentListByEffort = async () =>{
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/cwtr/getTeacherEffortStudents', {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({ className: className, schoolName: schoolName })
            });
            const data = await response.json();
            setStudentListByEffort(data?.data);
        } catch (error) {
            console.log("Error while getting students by efforts", error);
        }
    }

    
  const getStudentEffortReport = async()=>{
    setTableLoader(true);
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/cwtr/getStudentEffortReport', {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({ UserId: studentId, className: className,
                    schoolName :schoolName })
            });
            const data = await response.json();
            setStudentEffortReport(data?.data);
            setTableLoader(false);
        } catch (error) {
            console.log("Error while getting Student Effort Report ", error);
            setTableLoader(false);
        }
  }


  return (
    <div className='StudentEffortReport'>
        <Container>
        <Row className='justify-content-end'>
                    <Col md={3}>
                        <Form.Label>Select Any Student</Form.Label>
                        <Form.Select value={studentId} onChange={(e) => setStudentId(e.target.value)}>
                            <option value="All">All</option>
                          {
                            studentListByEffort !== undefined && studentListByEffort?.length > 0 
                            && studentListByEffort !== null ? studentListByEffort?.map((row) => {
                                return (
                                  <option value={row?.UserId}>{row?.DisplayName +' - '+ row?.UserId}</option>
                                )
                              }) : <option value=""></option>
                          }
                        </Form.Select>
                    </Col>
                </Row>

            
            {
                studentId==="All" ? 
                <Row className="outer-wrapper mt-4">
                    <div className="table-wrapper">
                            <table>
                                <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Student ID</th>
                                    <th>Student Name</th>
                                    <th>Avg. Duration (Sec.)</th>
                                    <th>Avg. Watch Duration (Sec.)</th>
                                    <th>Watch Percentage</th> 
                                </tr>
                                </thead>

                    
                    { tableLoader ? <Skeleton rows={6} cols={6}/> :
                    <>
                        <tbody>
                        {
                    studentEffortReport!== null && studentEffortReport !== undefined && 
                    studentEffortReport?.length > 0 ? 
                    studentEffortReport?.map((itm, index) => {
                        const { studentId, studentName, sumDurationInSeconds, sumWatchDurationInSeconds,percentageWatch } = itm
                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{index + 1}</td>
                                    <td>{studentId}</td>
                                    <td>{studentName}</td>
                                    <td>{sumDurationInSeconds}</td>
                                    <td>{sumWatchDurationInSeconds}</td>
                                    <td>{percentageWatch}</td>
                                </tr>
                            )
                        })
                    : <p className='noDataFound'>No Data Found</p>
                    }
                </tbody>
                </>
                }
                </table>
                        </div>
                    
                </Row> :

                <Row className="outer-wrapper mt-4">
                <Col md={4} className='mb-5 mb-md-0'>
                    <Card>
                        <h6 className='headline'>Content Watch Report</h6>
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Subject</th>
                                    <th>Watch %.</th>
                                    <th>Total Opens</th>
                                </tr>
                                </thead>

                    
                    { tableLoader ? <Skeleton rows={4} cols={4}/> :
                    <>
                        <tbody>
                        {
                    studentEffortReport?.SubjectData!== null && studentEffortReport?.SubjectData !== undefined && 
                    studentEffortReport?.SubjectData?.length > 0 ? 
                    studentEffortReport?.SubjectData?.map((itm, index) => {
                        const { Subject, WatchPercentage, TotalOpens } = itm
                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{index + 1}</td>
                                    <td>{Subject}</td>
                                    <td>{WatchPercentage}</td>
                                    <td>{TotalOpens}</td>
                                </tr>
                            )
                        })
                    : <p className='noDataFound'>No Data Found</p>
                    }
                </tbody>
                </>
                }
                </table>
                        </div>
                    </Card>
                </Col>

                <Col md={4} className='mb-5 mb-md-0'>
                    <Card>
                        <h6 className='headline'>Bloom's Taxonomy Report</h6>
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Cognitive Rigor</th>
                                    <th>Watch %.</th>
                                    <th>Total Opens</th>
                                </tr>
                                </thead>

                    
                    { tableLoader ? <Skeleton rows={4} cols={4}/> :
                    <>
                        <tbody>
                        {
                        studentEffortReport?.CognitiveRigorData!== null && studentEffortReport?.CognitiveRigorData !== undefined 
                        && studentEffortReport?.CognitiveRigorData?.length > 0 ? 
                        studentEffortReport?.CognitiveRigorData?.map((itm, index) => {
                        const { CognitiveRigor, WatchPercentage, TotalOpens } = itm
                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{index + 1}</td>
                                    <td>{CognitiveRigor}</td>
                                    <td>{WatchPercentage}</td>
                                    <td>{TotalOpens}</td>
                                </tr>
                            )
                        })
                    : <p className='noDataFound'>No Data Found</p>
                    }
                </tbody>
                </>
                }
                </table>
                        </div>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <h6 className='headline'>Difficulty Level Report</h6>
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Difficulty Level</th>
                                    <th>Watch %.</th>
                                    <th>Total Opens</th>
                                </tr>
                                </thead>

                    
                    { tableLoader ? <Skeleton rows={4} cols={4}/> :
                    <>
                        <tbody>
                        {
                        studentEffortReport?.DifficultyLevelData!== null && studentEffortReport?.DifficultyLevelData !== undefined && 
                        studentEffortReport?.DifficultyLevelData?.length > 0 ? 
                        studentEffortReport?.DifficultyLevelData?.map((itm, index) => {
                        const { DifficultyLevelData, WatchPercentage, TotalOpens } = itm
                            return (
                                <tr key={Math.random() * 999999999}>
                                    <td>{index + 1}</td>
                                    <td>{DifficultyLevelData}</td>
                                    <td>{WatchPercentage}</td>
                                    <td>{TotalOpens}</td>
                                </tr>
                            )
                        })
                    : <p className='noDataFound'>No Data Found</p>
                    }
                </tbody>
                </>
                }
                </table>
                        </div>
                    </Card>
                </Col>
                </Row>

            }
            

                
        </Container>

    </div>
  )
}

export default StudentEffortReport;