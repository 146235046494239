import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Skeleton from '../Common/Skeleton';

const StudentResultReport = () => {

    const { teacherId, className, schoolName} = useParams();

    const [tableLoader, setTableLoader] = useState(false);
    const [studentListByResult, setStudentListByResult] = useState([]);
    const [studentId, setStudentId] = useState('All');
    const [studentResultReport, setStudentResultReport] = useState([]);

    useEffect(()=>{
        getStudentListByResult();
      },[])

    useEffect(()=>{
        if (studentId) {
            getstudentResultReport();
        }
      },[studentId])

    const getStudentListByResult = async () =>{
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/cwtr/getTeacherResultStudents', {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({ className: className, schoolName: schoolName })
            });
            const data = await response.json();
            setStudentListByResult(data?.data);
        } catch (error) {
            console.log("Error while getting students by efforts", error);
        }
    }

    
  const getstudentResultReport = async()=>{
    setTableLoader(true);
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/cwtr/getstudentResultReport', {
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({ UserId:studentId, className:className, schoolName:schoolName })
            });
            const data = await response.json();
            setStudentResultReport(data?.data);
            setTableLoader(false);
        } catch (error) {
            console.log("Error while getting Student Effort Result ", error);
            setTableLoader(false);
        }
  }


  return (
    <div className='StudentResultReport'>
        <Container>
        <Row className='justify-content-end'>
                    <Col md={3}>
                        <Form.Label>Select Any Student</Form.Label>
                        <Form.Select value={studentId} onChange={(e) => setStudentId(e.target.value)}>
                            <option value="All">All</option>
                          {
                            setStudentListByResult !== undefined && studentListByResult?.length > 0 
                            && studentListByResult !== null ? studentListByResult?.map((row) => {
                                return (
                                  <option value={row?.StudentId}>{row?.DisplayName + ' - '+ row?.StudentId}</option>
                                )
                              }) : <option value=""></option>
                          }
                        </Form.Select>
                    </Col>
                </Row>


            {
                studentId === "All" ?
                 <Row className="outer-wrapper mt-4">
                    <div className="table-wrapper">
                            <table>
                                <thead>
                                <tr>
                                    <th>Sr.</th>
                                    <th>Student ID</th>
                                    <th>Student Name</th>
                                    <th>Exams Attempted</th>
                                    <th>Total Exams</th>
                                    <th>Accuracy</th> 
                                </tr>
                                </thead>
                    
                    { tableLoader ? <Skeleton rows={6} cols={6}/> :
                    <>
                        <tbody>
                        {
                            studentResultReport!== null && studentResultReport !== undefined && 
                            studentResultReport?.length > 0 ? 
                            studentResultReport?.map((itm, index) => {
                                const { studentId, studentName, examsAttempted, totalExams, accuracy } = itm
                                    return (
                                        <tr key={Math.random() * 999999999}>
                                            <td>{index + 1}</td>
                                            <td>{studentId}</td>
                                            <td>{studentName}</td>
                                            <td>{examsAttempted}</td>
                                            <td>{totalExams}</td>
                                            <td>{accuracy}</td>
                                        </tr>
                                    )
                                })
                            : <p className='noDataFound'>No Data Found</p>
                            }
                        </tbody>
                        </>
                        }
                </table>
                        </div>
                    
                </Row> :

                <Row className="outer-wrapper mt-4">
                    <Col md={4} className='mb-5 mb-md-0'>
                        <Card>
                            <h6 className='headline'>Assessment Report</h6>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Sr.</th>
                                        <th>Exam</th>
                                        <th>Accuracy</th>
                                        <th>Avg. Time</th>
                                    </tr>
                                    </thead>
                    
                        
                        { tableLoader ? <Skeleton rows={4} cols={4}/> :
                        <>
                            <tbody>
                            {
                           studentResultReport?.ExamNameData!== null && studentResultReport?.ExamNameData !== undefined && 
                           studentResultReport?.ExamNameData?.length > 0 ? 
                           studentResultReport?.ExamNameData?.map((itm, index) => {
                            const { ExamName, accuracy, averageTime } = itm
                                return (
                                    <tr key={Math.random() * 999999999}>
                                        <td>{index + 1}</td>
                                        <td>{ExamName}</td>
                                        <td>{accuracy}</td>
                                        <td>{averageTime}</td>
                                    </tr>
                                )
                            })
                        : <p className='noDataFound'>No Data Found</p>
                        }
                    </tbody>
                    </>
                }
                </table>
                            </div>
                        </Card>
                    </Col>

                    <Col md={4} className='mb-5 mb-md-0'>
                        <Card>
                            <h6 className='headline'>Bloom's Taxonomy Report</h6>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Sr.</th>
                                        <th>Cognitive Rigor</th>
                                        <th>Accuracy</th>
                                        <th>Avg. Time</th>
                                    </tr>
                                    </thead>
                    
                        
                        { tableLoader ? <Skeleton rows={4} cols={4}/> :
                        <>
                            <tbody>
                            {
                            studentResultReport?.CognitiveRigorData!== null && studentResultReport?.CognitiveRigorData !== undefined 
                            && studentResultReport?.CognitiveRigorData?.length > 0 ? 
                            studentResultReport?.CognitiveRigorData?.map((itm, index) => {
                            const { CognitiveRigor, accuracy, averageTime } = itm
                                return (
                                    <tr key={Math.random() * 999999999}>
                                        <td>{index + 1}</td>
                                        <td>{CognitiveRigor}</td>
                                        <td>{accuracy}</td>
                                        <td>{averageTime}</td>
                                    </tr>
                                )
                            })
                        : <p className='noDataFound'>No Data Found</p>
                        }
                    </tbody>
                    </>
                }
                </table>
                            </div>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card>
                            <h6 className='headline'>Difficulty Level Report</h6>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Sr.</th>
                                        <th>Difficulty Level</th>
                                        <th>Accuracy</th>
                                        <th>Avg. Time</th>
                                    </tr>
                                    </thead>
                    
                        
                        { tableLoader ? <Skeleton rows={4} cols={4}/> :
                        <>
                            <tbody>
                            {
                            studentResultReport?.DifficultyLevelData!== null && studentResultReport?.DifficultyLevelData !== undefined && 
                            studentResultReport?.DifficultyLevelData?.length > 0 ? 
                            studentResultReport?.DifficultyLevelData?.map((itm, index) => {
                            const { DifficultyLevelData, accuracy, averageTime } = itm
                                return (
                                    <tr key={Math.random() * 999999999}>
                                        <td>{index + 1}</td>
                                        <td>{DifficultyLevelData}</td>
                                        <td>{accuracy}</td>
                                        <td>{averageTime}</td>
                                    </tr>
                                )
                            })
                        : <p className='noDataFound'>No Data Found</p>
                        }
                    </tbody>
                    </>
                }
                </table>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
        </Container>

    </div>
  )
}

export default StudentResultReport;